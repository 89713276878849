import * as actionlist from '../actions/individualreportsactions';
import moment from 'moment';
const state = {
    search: '',
    page: 0,
    startdate: moment().date(1).toDate(),
    enddate: moment().endOf('month').toDate(),
    clubId: null,   
    individualreportlabelId:null
};

const getters = {
    page: state => state.page,
    search: state => state.search,
    startdate: state => state.startdate,
    enddate: state => state.enddate,
    clubId: state => state.clubId,
    individualreportlabelId: state => state.individualreportlabelId
};

const actions = {
    [actionlist.INDIVIDUALREPORTSLIST_SEARCH]: ({ commit }, search) => {
        commit(actionlist.INDIVIDUALREPORTSLIST_SEARCH, search);
    },
    [actionlist.INDIVIDUALREPORTSLIST_PAGE]: ({ commit }, page) => {
        commit(actionlist.INDIVIDUALREPORTSLIST_PAGE, page);
    },
    [actionlist.INDIVIDUALREPORTSLIST_STARTDATE]: ({ commit }, startdate) => {
        commit(actionlist.INDIVIDUALREPORTSLIST_STARTDATE, startdate);
    },
    [actionlist.INDIVIDUALREPORTSLIST_ENDDATE]: ({ commit }, enddate) => {
        commit(actionlist.INDIVIDUALREPORTSLIST_ENDDATE, enddate);
    },
    [actionlist.INDIVIDUALREPORTSLIST_CLUBID]: ({ commit }, clubId) => {
        commit(actionlist.INDIVIDUALREPORTSLIST_CLUBID, clubId);
    },
    [actionlist.INDIVIDUALREPORTSLIST_INDIVIDUALREPORTLABELID]: ({ commit }, individualreportlabelId) => {
        commit(actionlist.INDIVIDUALREPORTSLIST_INDIVIDUALREPORTLABELID, individualreportlabelId);
    },
};

const mutations = {
    [actionlist.INDIVIDUALREPORTSLIST_SEARCH]: (state, search) => {
        state.page = 0;
        state.search = search;
    },
    [actionlist.INDIVIDUALREPORTSLIST_PAGE]: (state, page) => {
        state.page = page;
    },
    [actionlist.INDIVIDUALREPORTSLIST_STARTDATE]: (state, startdate) => {
        state.page = 0;
        state.startdate = startdate;
    },
    [actionlist.INDIVIDUALREPORTSLIST_ENDDATE]: (state, enddate) => {
        state.page = 0;
        state.enddate = enddate;
    },
    [actionlist.INDIVIDUALREPORTSLIST_CLUBID]: (state, clubId) => {
        state.page = 0;
        state.clubId = clubId;
    },
    [actionlist.INDIVIDUALREPORTSLIST_INDIVIDUALREPORTLABELID]: (state, individualreportlabelId) => {
        state.page = 0;
        state.individualreportlabelId = individualreportlabelId;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
