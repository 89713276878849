import { render, staticRenderFns } from "./inputtextarea.vue?vue&type=template&id=2e57b610&"
import script from "./inputtextarea.js?vue&type=script&lang=js&"
export * from "./inputtextarea.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\Projecten\\EigenSteil\\BontRooster\\Frontend\\BontScheduler.Portal\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2e57b610')) {
      api.createRecord('2e57b610', component.options)
    } else {
      api.reload('2e57b610', component.options)
    }
    module.hot.accept("./inputtextarea.vue?vue&type=template&id=2e57b610&", function () {
      api.rerender('2e57b610', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "ClientApp/components/common/inputs/inputtextarea.vue"
export default component.exports