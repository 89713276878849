var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row row-sm" }, [
    _c("div", { staticClass: "col form-inline mg-b-5" }, [
      _c("div", [
        _c(
          "button",
          {
            staticClass: "btn btn-sm btn-secondary wd-80",
            attrs: { type: "button" },
            on: { click: _vm.previousPeriod }
          },
          [
            _c("i", { staticClass: "fas fa-caret-left" }),
            _vm._v(" " + _vm._s(_vm.$t("actions.previous")))
          ]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-sm btn-secondary wd-80",
            attrs: { type: "button" },
            on: { click: _vm.nextPeriod }
          },
          [
            _vm._v(_vm._s(_vm.$t("actions.next")) + " "),
            _c("i", { staticClass: "fas fa-caret-right" })
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "mg-l-15 form-inline" }, [
        _c("div", { staticClass: "tx-bold tx-gray-900" }, [
          _vm.calendarType == "week"
            ? _c("span", [
                _vm._v(_vm._s(_vm._f("period")(_vm.startDate, _vm.endDate)))
              ])
            : _c("span", [
                _vm._v(_vm._s(_vm._f("month")(_vm.period, _vm.year)))
              ])
        ]),
        _vm._v(" "),
        _vm.calendarType == "week"
          ? _c("div", { staticClass: "mg-l-5" }, [
              _vm._v(
                "\r\n                    " +
                  _vm._s(_vm.$t("periods.week")) +
                  " " +
                  _vm._s(_vm.period) +
                  "\r\n                "
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "mg-l-30" }, [
          _c("label", { staticClass: "form-control-label" }, [
            _vm._v(_vm._s(_vm.$t("activitylabel")) + ":")
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mg-l-5 wd-200" },
          [
            _c("selectoptions", {
              attrs: {
                url: "/activitylabel/all",
                value: _vm.activityLabelId,
                settings: {
                  width: "100%",
                  allowClear: true,
                  placeholder: "Selecteer"
                },
                emptyname: "Kiezen"
              },
              model: {
                value: _vm.activityLabelId,
                callback: function($$v) {
                  _vm.activityLabelId = $$v
                },
                expression: "activityLabelId"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "mg-l-30" }, [
          _c("label", { staticClass: "form-control-label" }, [
            _vm._v(_vm._s(_vm.$t("showperiod")) + ":")
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mg-l-5 wd-50" },
          [
            _c("inputtoggle", {
              attrs: { value: _vm.showPeriod },
              model: {
                value: _vm.showPeriod,
                callback: function($$v) {
                  _vm.showPeriod = $$v
                },
                expression: "showPeriod"
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "ml-auto form-inline" }, [
        !_vm.isAdministrator
          ? _c("div", { staticClass: "form-inline" }, [
              _c("div", { staticClass: "mg-l-15" }, [
                _c("i", {
                  staticClass: "fa fa-square mg-l-2 tx-red-8 bd",
                  staticStyle: { color: "rgb(255, 19, 10)" }
                }),
                _vm._v(
                  "\r\n                        " +
                    _vm._s(_vm.$t("unregisterclose")) +
                    "\r\n                    "
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "mg-l-15" }, [
                _c("i", { staticClass: "fa fa-square mg-l-2 tx-white-8 bd" }),
                _vm._v(
                  "\r\n                        " +
                    _vm._s(_vm.$t("notsignedup")) +
                    "\r\n                    "
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "mg-x-5" }, [
                _c("i", {
                  staticClass: "fa fa-square mg-l-2 bg-warning tx-warning bd"
                }),
                _vm._v(" "),
                _vm.isClient || _vm.isClientReadOnly
                  ? _c("span", [_vm._v(_vm._s(_vm.$t("onwaitinglist")))])
                  : _c("span", [_vm._v(_vm._s(_vm.$t("signedup")))])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "mg-r-15" }, [
                _c("i", {
                  staticClass: "fa fa-square mg-l-2 bg-success tx-success bd"
                }),
                _vm._v(" "),
                _vm.isClient || _vm.isClientReadOnly
                  ? _c("span", [_vm._v(_vm._s(_vm.$t("signedup")))])
                  : _c("span", [_vm._v(_vm._s(_vm.$t("planned")))])
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "btn-group", attrs: { role: "group" } }, [
          _c(
            "button",
            {
              staticClass: "btn btn-sm btn-outline-secondary wd-80",
              class: { active: _vm.calendarType == "week" },
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  return _vm.changeCalendarType("week")
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("periods.week")))]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-sm btn-outline-secondary wd-80",
              class: { active: _vm.calendarType == "month" },
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  return _vm.changeCalendarType("month")
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("periods.month")))]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }