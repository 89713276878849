import { type } from 'jquery';
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
    name: 'CalendarControl',
    props: {
        year: {
            type: Number,
            default: moment().year()
        },
        period: {
            type: Number,
            default: 1
        },
        startDate: {
            type: Date
        },
        endDate: {
            type: Date
        },
        calendarType: {
            type: String,
            default: 'week'
        },      
    },
    data() {
        return {
            activityLabelId: 0,
            showPeriod: false
        };

    },
    watch: {
        activityLabelId() {
            this.$emit('activitylabelchange', this.activityLabelId);
        },
        showPeriod() {
            this.$emit('showPeriodchange', this.showPeriod);
        }
    },
    methods: {
        nextPeriod() {
            let nextPeriod = this.period + 1;
            let nextYear = this.year;
            if (this.calendarType == 'month') {
                if (nextPeriod > 12) {
                    nextPeriod = 1;
                    nextYear += 1;
                }
            } else if (this.calendarType == 'week') {
                if (nextPeriod > this.weeksInYear(this.year)) {
                    nextPeriod = 1;
                    nextYear += 1;
                }
            }

            this.$emit('periodchange', nextPeriod);
            this.$emit('yearchange', nextYear);
            console.log('next' + this.endDate);
        },
        previousPeriod() {
            let previousPeriod = this.period - 1;
            let previousYear = this.year;
            if (previousPeriod <= 0) {
                if (this.calendarType == 'month') {
                    previousPeriod = 12;
                    previousYear -= 1;
                } else if (this.calendarType == 'week') {
                    previousYear -= 1;
                    previousPeriod = this.weeksInYear(previousYear);
                }
            }
            this.$emit('periodchange', previousPeriod);
            this.$emit('yearchange', previousYear);
            console.log('prev' + this.endDate);
        },
        weeksInYear(year) {
            return Math.max(
                moment(new Date(year, 11, 31)).isoWeek(),
                moment(new Date(year, 11, 31 - 7)).isoWeek()
            );
        },
        changeCalendarType(type) {
            if (this.calendarType == type) return;
            let now = moment();
            let month = moment(this.endDate).format('M');
            this.$emit('calendartypechange', type);
            this.$emit('periodchange', type == 'week' ? now.isoWeek() : parseInt(month));
            this.$emit('yearchange', now.year());
        }
    },
    computed: {
        ...mapGetters('auth', [
            'isAdministrator',
            'isAttendant',
            'isClient',
            'isClientReadOnly',
            'isLimitedAdministrator'
        ])
    },
};